<!--
 * @Description: 子账号
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:35:13
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-30 15:43:09
-->
<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="姓名">
        <el-input v-model="query.q" placeholder="请输入姓名、手机号"></el-input>
      </el-form-item>
      <el-form-item label="岗位">
        <el-select v-model="query.postId" placeholder="岗位搜索" clearable>
          <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in postList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus" v-if="is_can_edit">新增</el-button>
  </el-card>

  <el-card class="box data emp-container">
    <el-row :gutter="20">
      <el-col :span="5" style="height:100%">
        <div class="dept-box">
          <el-scrollbar>
            <el-tree
              ref="tree"
              :data="deptList"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              :props="{ children: 'children', label: 'name' }"
              default-expand-all
              @current-change="treeNodeChange"
            />
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :span="19" v-loading="loading">
        <el-table :data="tableData.data" border @sort-change="onSort">
          <!-- <el-table-column prop="id" label="编号" width="80" sortable></el-table-column> -->
          <el-table-column label="头像" width="100">
            <template #default="scope">
              <el-avatar :src="scope.row.avatar_pic ? scope.row.avatar_pic : avatar"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="chs_name" label="姓名" width="120" sortable></el-table-column>
          <el-table-column prop="first_spell" label="首字母" width="80"></el-table-column>
          <el-table-column prop="mobile_phone" label="手机号" width="140"></el-table-column>
          <el-table-column prop="user_name" label="账号" width="140"></el-table-column>
          <el-table-column prop="sex_text" label="性别" width="100"></el-table-column>
          <el-table-column label="状态" width="80">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.status == 0">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.status == 10">停用</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="dept_full_name" label="部门" width="180"></el-table-column>
          <el-table-column prop="job.name" label="职位" width="180"></el-table-column>
          <el-table-column label="岗位角色" min-width="200">
            <template #default="scope">
               {{scope.row.post_list.map(x=>x.name).join('、')}}
            </template>
          </el-table-column>
          <el-table-column label="部门主管" width="100">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.is_master">是</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="create_at" label="创建时间" width="180"></el-table-column>
          <el-table-column label="操作" fixed="right" width="140">
            <template #default="scope">
              <el-button type="primary" link size="small" @click="onEdit(scope.row)" v-if="is_can_edit">修改</el-button>
              <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
                <template #reference>
                  <el-button type="danger" link size="small" class="text-danger">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.counts > 0"
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="query.pageIndex"
          :page-sizes="[20, 40, 60, 80, 100]"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="tableData.counts"
        ></el-pagination>
      </el-col>
    </el-row>
  </el-card>
  <edit-emp
    :item="current"
    :depts="originalDept"
    :posts="postList"
    :statusList="statusList"
    @close="current=null"
    @success="loadData"
  ></edit-emp>
</template>

<script>
import avatar from "@/assets/avatar.png";
import EditEmp from "./edit_emp.vue";
import common_api from "@/http/common_api";
import dept_api from "../../../http/dept_api";
export default {
  components: {
    EditEmp,
  },
  data() {
    return {
      loading: false,
      current: null,
      //本地默认头像
      avatar: avatar,
      originalDept: [],
      deptList: [],
      postList: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //状态
      statusList: [],
      is_can_edit:false,
      is_can_del:false,
    };
  },
  created() {
    this.is_can_edit = this.$power('seller/v1/emp/edit');
    this.is_can_del = this.$power('seller/v1/emp/del');

    //menus选择
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.statusList = res.data.emp_status;
      }
    });
    this.loadDepts();
    this.loadPost();
    this.loadData();

  },
  methods: {
    /**
     * 加载部门
     */
    loadDepts() {
      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.originalDept = res.data;
          this.deptList = [
            {
              id: "",
              name: "全部",
            },
          ].concat(res.data);
          //tree默认选中第一个
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey("");
          });
        }
      });
    },
    /**
     * 加载岗位
     */
    loadPost() {
      this.$http.get("seller/v1/post/all").then((res) => {
        if (res.code == 0) {
          this.postList = res.data;
        }
      });
    },
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post("seller/v1/emp", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/emp/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 部门选择
     * tree节点点击事件
     */
    treeNodeChange(item) {
      this.query.deptId = item.id;
      this.loadData();
    },
  },
};
</script>

<style>
.dept-box {
  height: 100%;
  border-radius: var(--el-card-border-radius);
  border: 1px solid var(--el-card-border-color);
  background-color: var(--el-card-bg-color);
  overflow: hidden;
  color: var(--el-text-color-primary);
  transition: var(--el-transition-duration);
}

.dept-box .el-card__body {
  height: 100% !important;
}

.emp-container .el-row {
  height: 100%;
}

.emp-container .el-row .el-col {
  display: flex;
  flex-direction: column;
}
</style>